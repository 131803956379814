[data-v-022f3066] .el-table--small td, .el-table--small th[data-v-022f3066] {
  line-height: 26px;
}
[data-v-022f3066] .el-table .cell {
  line-height: 32px;
}
[data-v-022f3066] .el-tag--small {
  display: none;
}
[data-v-022f3066] .el-table--border {
  border-bottom: 1px solid #cccccc;
}